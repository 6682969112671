(function ($) {
    "use strict";
    $(function () {
        $('#mailgroup-users-filter').submit(function (e) {
            e.preventDefault();

            const roles_select = $('#roles_select').val();
            const interests_select = $('#interests_select').val();
            const participated_interests_select = $('#participated_interests_select').val();
            const guessed_interests_select = $('#guessed_interests_select').val();
            const interests_connection_type_select = $('#interests_connection_type_select').val();
            const newsletter_select = $('#newsletter_select').val();
            const company_assignment_select = $('#company_assignment_select').val();
            const registrations_counter = $('input[name=registrations_counter]').val();
            const scope_of_activities_select = $('#scope_of_activities_select').val();
            const banned_mail_select = $('#banned_mail_select').val();
            const id_not_in = $('#id_not_in').val();
            const q = $('input[name="q"]').val();

            let order_by = $('#order_by').val();
            let order_dir = $('#order_dir').val();
            let page = $('#page').val();
            let params = '';

            if (roles_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'roles_select=' + roles_select;
            }

            if (interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'interests_select=' + interests_select;
            }

            if (participated_interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'participated_interests_select=' + participated_interests_select;
            }

            if (guessed_interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'guessed_interests_select=' + guessed_interests_select;
            }

            if (interests_connection_type_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'interests_connection_type_select=' + interests_connection_type_select;
            }

            if (newsletter_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'newsletter_select=' + newsletter_select;
            }

            if (company_assignment_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'company_assignment_select=' + company_assignment_select;
            }

            if (registrations_counter) {
                params === '' ? params = '?' : params += '&';
                params += 'registrations_counter=' + registrations_counter;
            }

            if (scope_of_activities_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'scope_of_activities_select=' + scope_of_activities_select;
            }

            if (banned_mail_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'banned_mail_select=' + banned_mail_select;
            }

            if (q) {
                params === '' ? params = '?' : params += '&';
                params += 'q=' + q;
            }

            if (id_not_in.length) {
                params === '' ? params = '?' : params += '&';
                params += 'id_not_in=' + id_not_in;
            }

            if (order_dir) {
                params === '' ? params = '?' : params += '&';
                params += 'order_dir=' + order_dir;
            }

            if (order_by) {
                params === '' ? params = '?' : params += '&';
                params += 'order_by=' + order_by;
            }

            if (page) {
                params === '' ? params = '?' : params += '&';
                params += 'page=' + page;
            }

            const url = $('#mailgroup-users-filter').attr('action') + params;
            const ajaxUrl = $('#mailgroup-users-filter').attr('action') + 'Filter' + params;

            history.pushState({url: url, title: url}, url, url);

            $.ajax({
                url: ajaxUrl,
                method: 'GET',
                dataType: 'json',
                success(res) {
                    $('.ajax-content').empty();

                    if (res.usersContentHTML != null) {
                        $('.ajax-content').html(res.usersContentHTML);
                        let exportUrl = new URL($('#mailgroup_form').attr('action'));
                        exportUrl.search = window.location.search;
                        $('#mailgroup_form').attr('action', exportUrl.href);

                        // No content and current page is not 1 -> return to first page
                        if (res.current_page_users == 0 && $('#page').val() != 1) {
                            $('#page').val(1);
                            $('#mailgroup-users-filter').submit();
                        }
                    }
                },
                error(res) {
                    console.log(res);
                }
            });
        });

        $('#mailgroup-users-filter').on('change', function () {
            $('#page').val('');
            $("#mailgroup-users-filter").submit();
        });

        if ($('#mailgroup-users-filter').length) {
            $('#mailgroup-users-filter').submit();
        }

        $(document).on('click', '#action_type_merge_mailgroups', function () {
            $('#action_type').val('merge_mailgroups');
            $('#mailgroups_action_form').submit();
        });

        $(document).on('click', '#actionMergeMailgroupsModal', function () {
            $('#action_type').val('merge_mailgroups');
        });

        $(document).on('click', '.remove-user-from-list', function (e) {
            let userId = $(this).data('id-not-in');

            let oldIdNotIn = $('#id_not_in').val();
            if (oldIdNotIn) {
                userId = oldIdNotIn + ',' + userId;
            }
            $('#id_not_in').val(userId);

            if ($('#mailgroup-users-filter').length) {
                $('#mailgroup-users-filter').submit();
            }
        })
    });
})(jQuery);
