$(function () {
    function initFileUpload() {
        /**
         * Upload files
         */
        $('.fileupload').fileupload({
            dataType: 'json',
            type: 'POST',
            dropZone: $('.upload-block'),
            start: function (e, data) {
                $(this).siblings('.upload-block').addClass('loading');
            },
            done: function (e, data) {
                if (data.result.status == 'error') {
                    $.fancyModal({
                        title: data.result.title,
                        message: data.result.message,
                    });
                } else {
                    $(this).siblings('.upload-block').first().before(data.result.imageHtml);

                    if ($(this).parent().data('max-counter')) {
                        if ($(this).siblings('.uploaded-img, .uploaded-file').length === $(this).parent().data('max-counter')) {
                            $(this).siblings('.upload-block').addClass('d-none');
                        }
                    }
                }

                $(this).siblings('.upload-block').removeClass('loading');
            },
            progressall: function (e, data) {
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $('#progress .bar').css('width', progress + '%').find('.percent').html(progress + '%');
            }
        });
    }

    initFileUpload();

    /**
     * Delete upload
     */
    $(document).on('click', '.delete-photo, .delete-file', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var current_button = $(this);

        // Open customized confirmation dialog window
        $.fancyConfirm({
            title: 'Biztosan törlöd a tartalmat?',
            message: '',
            okButton: 'Törlés',
            noButton: 'Mégsem',
            callback: function (value) {
                if (value) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        success: function (response) {
                            if (response.status == 'ok') {
                                if (current_button.parent().parent().data('max-counter')) {
                                    if (current_button.parent().siblings('.uploaded-img, .uploaded-file').length < current_button.parent().parent().data('max-counter')) {
                                        current_button.parent().siblings('.upload-block').removeClass('d-none');
                                    }
                                }

                                current_button.parent().remove();
                                initFileUpload();
                            } else {
                                $.fancyModal({
                                    title: response.title,
                                    message: response.message,
                                });
                            }
                        }
                    });
                }
            }
        });
    });

    $('.uploaded-img-wrapper').sortable({
        placeholder: "ui-state-highlight",
        items: '.uploaded-img',
        'revert': 300,	// Animation duration to text back to position (false -> animation off)
        update: function (event, ui) {
            var orders = $('.photo-small-image').map(function () {
                return $(this).data('order');
            }).get();
            var serialized_data = "orders=" + orders;

            $.ajax({
                url: $(this).data('reorder-url'),
                method: 'POST',
                datatype: 'JSON',
                data: serialized_data,
                success: function (response) {
                    if (response.status == 'ok') {
                        $('.photo-small-image').each(function (i) {
                            $(this).data('order', i + 1);
                            $(this).attr('data-order', i + 1);
                        });
                        toastr.success(response.message);
                    } else {
                        toastr.error(response.message);
                    }
                },
                error: function (response) {
                    if (response.message) {
                        toastr.error(response.message);
                    } else {
                        toastr.error('Hiba történt, töltsd újra az oldalt és próbáld újra');
                    }
                }
            });
        }
    }).disableSelection();

    $(document).on('click', '.photo-small-image', function (e) {
        e.preventDefault();

        let photos = [];
        let index = $(this).index();

        if ($(this).parent().hasClass('slick-gallery')) {
            $(this).parent().children('.photo-small-image').each(function (i) {
                photos.push({
                    src: $(this).attr('src'),
                    opts: {
                        thumb: $(this).attr('src')
                    }
                });
            });
        } else {
            index = $(this).parent().parent().index();

            $('.photo-small-image').each(function (i) {
                photos.push({
                    src: $(this).attr('src'),
                    opts: {
                        thumb: $(this).attr('src')
                    }
                });
            });
        }

        $.fancybox.open(photos, {
            loop: true,
            index: index,
            animationEffect: "zoom-in-out",
            transitionEffect: "zoom-in-out"
        });
    });
});
