$(function () {
    let calendar_element = $('#calendar');

    if (calendar_element.length > 0) {
        var miniType = (calendar_element.data('mini-calendar') === true);
        var listView = miniType ? 'listMonth,dayGridMonth' : 'dayGridMonth,timeGridWeek,listMonth';
        const default_blurred = calendar_element.hasClass('blurred');
        new FullCalendar.Calendar(document.getElementById('calendar'), {
            initialView: miniType ? 'listMonth' : 'dayGridMonth',
            locale: 'hu',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: listView
            },
            listDaySideFormat: false,
            eventSources: [{
                url: calendar_element.data('href'),
                failure: function () {
                    toastr.error('Hiba történt az rendezvények lekérése közben');
                },
                success(res) {
                    if (!default_blurred) {
                        if (res.length == 0) {
                            calendar_element.addClass('blurred');
                            $('#calendar__no-events-text').removeClass('d-none');
                        } else {
                            calendar_element.removeClass('blurred');
                            $('#calendar__no-events-text').addClass('d-none');
                        }
                    }
                }
            }],
            eventClick: function (info) {
                info.jsEvent.preventDefault();

                if (info.event.url) {
                    window.open(info.event.url);
                }
            },
            contentHeight: 'auto',
            handleWindowResize: true
        }).render();
    }

    $('#events-filter').submit(function (e) {
        e.preventDefault();

        const event_type_select = $('#event_type_select').val();
        const event_form_select = $('#event_form_select').val();
        const specialities_select = $('#specialities_select').val();
        const q = $('input[name=q]').val();

        let page = $('#page').val();
        let params = '';

        if (event_type_select.length) {
            params === '' ? params = '?' : params += '&';
            params += 'event_type_select=' + event_type_select;
        }

        if (event_form_select.length) {
            params === '' ? params = '?' : params += '&';
            params += 'event_form_select=' + event_form_select;
        }

        if (specialities_select.length) {
            params === '' ? params = '?' : params += '&';
            params += 'specialities_select=' + specialities_select;
        }

        if (q) {
            params === '' ? params = '?' : params += '&';
            params += 'q=' + q;
        }

        if (page) {
            params === '' ? params = '?' : params += '&';
            params += 'page=' + page;
        }

        const url = $('#events-filter').attr('action') + params;
        const ajaxUrl = $('#events-filter').attr('action') + 'Filter' + params;

        history.pushState({url: url, title: url}, url, url);

        $.ajax({
            url: ajaxUrl,
            method: 'GET',
            dataType: 'json',
            success(res) {
                $('.ajax-content').empty();

                if (res.eventsContentHTML != null) {
                    $('.ajax-content').html(res.eventsContentHTML);

                    // No content and current page is not 1 -> return to first page
                    if (res.current_page_events == 0 && $('#page').val() != 1) {
                        $('#page').val(1);
                        $('#events-filter').submit();
                    }
                }
            },
            error(res) {
                console.log(res);
            }
        });
    });

    function getURLParameter(url, name) {
        return (RegExp(name + '=' + '(.+?)(&|$)').exec(url) || [, null])[1];
    }

    $(document).on('click', '.ajax-content a.page-link', function (e) {
        e.preventDefault();
        $('#page').val(getURLParameter($(this).attr('href'), 'page'));

        if ($('#events-filter').length) {
            $('#events-filter').submit();
        }
    });

    $('#events-filter').on('change', function () {
        $('#page').val('');
        $("#events-filter").submit();
    });

    if ($('#events-filter').length) {
        $('#events-filter').submit();
    }

    $(document).on('click', '#action_type_confirm', function (e) {
        $('#action_type').val('confirm');
        $('#event_registrations_action_form').submit();
    });

    $(document).on('click', '#action_type_cancel', function (e) {
        $('#action_type').val('cancel');
        $('#event_registrations_action_form').submit();
    });

    $(document).on('click', '#action_type_create_mailgroup', function (e) {
        $('#action_type').val('create_mailgroup');
        $('#event_registrations_action_form').submit();
    });

    $(document).on('click', '#actionConfirmTemplateModal', function (e) {
        $('#action_type').val('confirm');
    });

    $(document).on('click', '#actionCancelTemplateModal', function (e) {
        $('#action_type').val('cancel');
    });

    $(document).on('click', '#actionCreateMailgroupTemplateModal', function (e) {
        $('#action_type').val('create_mailgroup');
    });
});
