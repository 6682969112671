(function ($) {
    "use strict";
    $(function () {
        $('#interest_form #parent_id').change(function () {
            if ($(this).val() !== '') {
                $('#code').closest('.row').attr('style', 'display: flex !important');
            } else {
                $('#code').closest('.row').attr('style', 'display: none !important');
            }
        });
    });
})(jQuery);
