$(function () {
    function initMap() {
        if ($('#g-map').length > 0) {
            var options = {
                center: new google.maps.LatLng(47.410741, 19.006286),
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: false,
                fullscreenControl: true
            };

            if ($('#g-map').data('latitude') && $('#g-map').data('longitude') && $('#g-map').data('latitude') != '') {
                options['center'] = new google.maps.LatLng($('#g-map').data('latitude'), $('#g-map').data('longitude'));
            }

            var map = new google.maps.Map(document.getElementById('g-map'), options);
            map.setOptions({
                minZoom: 5,
                maxZoom: 19,
                zoom: 17
            });

            if ($('#g-map').data('latitude') && $('#g-map').data('longitude') && $('#g-map').data('latitude') != '') {
                var marker = new google.maps.Marker({
                    position: options.center,
                    map: map,
                    /*icon: {
                        url: baseUrl+'/img/svg/marker.svg',
                        origin: new google.maps.Point(0, 0),
                        scaledSize: new google.maps.Size(30, 35),
                        labelOrigin: new google.maps.Point(75, 17)
                    }*/
                });
            }
        }
    }

    initMap();

    function initAutocomplete() {
        if ($('#location_map').length > 0) {
            var options = {
                center: new google.maps.LatLng(47.5005885, 19.0379379),
                streetViewControl: false,
                componentRestrictions: {country: "hu"},
                zoom: 13
            };

            if ($('#location_map').data('latitude') && $('#location_map').data('longitude')) {
                options['center'] = new google.maps.LatLng($('#location_map').data('latitude'), $('#location_map').data('longitude'));
                options['zoom'] = 17;
            }

            const map = new google.maps.Map(document.getElementById("location_map"), options);

            if ($('#location_map').data('latitude') && $('#location_map').data('longitude') && $('#location_map').data('latitude') != '') {
                var marker = new google.maps.Marker({
                    position: options.center,
                    map: map
                });
            }

            // Create the search box and link it to the UI element.
            const input = document.getElementById('location_address');
            const searchBox = new google.maps.places.SearchBox(input);

            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            let markers = [];

            // Listen for the event fired when the user selects a prediction and retrieve more details for that place.
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length == 0) {
                    return;
                }

                const geocoder = new google.maps.Geocoder;
                geocoder.geocode({'placeId': places[0].place_id}, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        const lat = results[0].geometry.location.lat();
                        const lng = results[0].geometry.location.lng();
                        setLocationCoordinates(lat, lng);
                    }
                });

                // Clear out the old markers.
                markers.forEach((marker) => {
                    marker.setMap(null);
                });
                markers = [];
                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry) {
                        console.log("Nincs a megadott címre találat!");
                        return;
                    }
                    const icon = {
                        url: place.icon,
                        size: new google.maps.Size(71, 71),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(17, 34),
                        scaledSize: new google.maps.Size(25, 25),
                    };
                    // Create a marker for each place.
                    markers.push(
                        new google.maps.Marker({
                            position: place.geometry.location,
                            map: map,
                            title: place.name,
                        })
                    );

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                });

                map.fitBounds(bounds);
            });
        }
    }

    function setLocationCoordinates(lat, lng) {
        document.getElementById("location_lat").value = lat;
        document.getElementById("location_lng").value = lng;
    }

    initAutocomplete();
});

