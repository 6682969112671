(function ($) {
    "use strict";
    $(function () {

        $('.admin-navbar-toggler').on('click', function () {
            $('.sidenav').toggleClass('active');
        });

        /*Cookie Policy*/
        function cookiesPolicyBar() {
            // Check cookie
            if ($.cookie('hunder-cookiepolicy') != "active") $('#cookie').show(); //Assign cookie on click

            $('#cookieAcceptBarConfirm').on('click', function () {
                $.cookie('hunder-cookiepolicy', 'active', {
                    expires: 31,
                    path: '/'
                });
                $('#cookie').fadeOut();
            });
        }

        cookiesPolicyBar();
    });
})(jQuery);
