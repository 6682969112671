(function ($) {

    $.fancyModal = function (opts) {
        opts = $.extend(true, {
            title: '',
            message: '',
            afterShow: '',
            afterClose: '',
            closeExisting: false,
            extraBtn: ''
        }, opts || {});

        $.fancybox.open({
            type: 'html',
            src:
                '<div class="animated-modal">' +
                '<h5 class="font-weight-normal ls-0-1em">' + opts.title + '</h5>' +
                '<p>' + opts.message + '</p>' +
                '<div class="d-flex d-sm-block flex-column">' + opts.extraBtn + '</div>' +
                '</div>',
            opts: {
                touch: false,
                afterShow: opts.afterShow,
                afterClose: opts.afterClose,
                closeExisting: opts.closeExisting,
                autoFocus: false,
                btnTpl: {
                    smallBtn:
                        '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="Bezárás">' +
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
                        "</button>"
                },
                animationEffect: "zoom-in-out",
                transitionEffect: "zoom-in-out"
            }
        })
    };

    $.fancyConfirm = function (opts) {
        opts = $.extend(true, {
            title: 'Biztos vagy benne?',
            message: '',
            okButton: 'Igen',
            noButton: 'Mégse',
            callback: $.noop
        }, opts || {});

        $.fancybox.open({
                type: 'html',
                src:
                    '<div class="animated-modal">' +
                    '<h5 class="font-weight-normal ls-0-1em">' + opts.title + '</h5>' +
                    '<p>' + opts.message + '</p>' +
                    '<div>' +
                    '<button data-value="1" data-fancybox-close class="btn btn-secondary mr-4 mt-4">' + opts.okButton + '</button>' +
                    '<button data-value="0" data-fancybox-close class="btn btn-primary mt-4 ml-2">' + opts.noButton + '</button>' +
                    '</div>' +
                    '</div>',
                opts: {
                    animationDuration: 200,
                    baseTpl:
                        '<div class="fancybox-container fc-container" role="dialog" tabindex="-1">' +
                        '<div class="fancybox-bg"></div>' +
                        '<div class="fancybox-inner">' +
                        '<div class="fancybox-stage"></div>' +
                        '</div>' +
                        '</div>',
                    afterClose: function (instance, current, e) {
                        var button = e ? e.target || e.currentTarget : null;
                        var value = button ? $(button).data('value') : 0;

                        opts.callback(value);
                    },
                    autoFocus: false,
                    btnTpl: {
                        smallBtn:
                            '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="Bezárás">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg>' +
                            "</button>"
                    },
                    animationEffect: "zoom-in-out",
                    transitionEffect: "zoom-in-out"
                }
            }
        );
    };

    "use strict";
    $(function () {

        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300",
            "hideDuration": "0",
            "timeOut": "0",
            "extendedTimeOut": "0",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "closeHtml": '<button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" width="25" height="25"><circle stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="180" cy="180" r="171"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="243" x2="243" y2="117"/><line stroke-width="18" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="117" y1="117" x2="243" y2="243"/></svg></button>'
        };

        objectFitImages();

        $('.js-custom-select').each(function (i, obj) {
            $(obj).selectize({
                plugins: ["drag_drop"],
                delimiter: ',',
                persist: false,
                usePlaceholder: true,
                create: $(obj).hasClass('enable-create-option'),
                onDropdownOpen: function () {
                    if ($(obj).hasClass('allow-empty-option')) {
                        this.clear();
                    }
                },
                onChange: function () {
                    if ($(obj).hasClass('template_part_selector')) {
                        let textarea_id = $(obj).siblings('textarea')[0].id;
                        tinymce.get(textarea_id).setContent(this.getValue());
                    }
                },
                render: {
                    option_create: function (data, escape) {
                        return '<div class="create"><strong>' + escape(data.input) + '</strong> hozzáadása</div>';
                    }
                }
            });
        });

        $('.js-custom-select.selectized:not(.no-js)').each(function (i, obj) {
            var $this = $(this);
            // Clear button add on document load if necessary
            if (obj.selectize.items.length !== 0) {
                $(this).parent().parent().addClass('has-clear');
                $(this).parent().append($('<span class="clear-button"/>').click(function () {
                    obj.selectize.clear(true);
                }));
            }
        });

        $(".selectize-input input[placeholder]").attr("style", "width: 100%;");

        $('input.form-control').on('input propertychange', function (e) {
            var $this = $(this);
            if (!$this.hasClass('datetimepicker-input')) {
                if ($this.hasClass('is-invalid')) {
                    $this.removeClass('is-invalid');
                }

                if ($this.closest('.form-group').hasClass('error')) {
                    $this.closest('.form-group').removeClass('error');
                }
            }

        });

        $('input.form-control.datetimepicker-input').on("show.datetimepicker", function (e) {
            var $this = $(this);
            if ($this.hasClass('is-invalid')) {
                $this.removeClass('is-invalid');
            }

            if ($this.closest('.form-group').hasClass('error')) {
                $this.closest('.form-group').removeClass('error');
            }

        })

        $('textarea.form-control').on('input propertychange', function (e) {
            var $this = $(this);
            if ($this.hasClass('is-invalid')) {
                $this.removeClass('is-invalid');
            }

            if ($this.closest('.form-group').hasClass('error')) {
                $this.closest('.form-group').removeClass('error');
            }
        });

        $('.datetimepicker-input').datetimepicker({
            locale: 'hu',
            format: 'YYYY-MM-DD HH:mm',
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'far fa-arrow-up',
                down: 'far fa-arrow-down',
                previous: 'far fa-chevron-left',
                next: 'far fa-chevron-right',
                today: 'far fa-calendar-check-o',
                clear: 'far fa-trash',
                close: 'far fa-times'
            },
            tooltips: {
                today: 'Ugrás a mai napra',
                clear: 'Kiválasztás törlése',
                close: 'Bezárás',
                selectDate: 'Dátum kiválasztása',
                selectMonth: 'Hónap kiválasztása',
                prevMonth: 'Előzző hónap',
                nextMonth: 'Következő hónap',
                selectYear: 'Év kiválasztása',
                prevYear: 'Előzző év',
                nextYear: 'Következő év',
                selectDecade: 'Évtized kiválasztása',
                prevDecade: 'Előzző évtized',
                nextDecade: 'Következő évtized',
                prevCentury: 'Előzző évszázad',
                nextCentury: 'Következő évszázad',
                incrementHour: 'Óra növelése',
                pickHour: 'Óra kiválasztása',
                decrementHour: 'Óra csökkentése',
                incrementMinute: 'Perc növelése',
                pickMinute: 'Perc kiválasztása',
                decrementMinute: 'Perc csökkentése',
                incrementSecond: 'Másodperc növelése',
                pickSecond: 'Másodperc kiválasztása',
                decrementSecond: 'Másodperc csökkentése'
            }
        });

        /**
         * Delete item
         */
        $(document).on('click', '.delete-item', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var current_button = $(this);

            // Open customized confirmation dialog window
            $.fancyConfirm({
                title: 'Biztosan törlöd a tartalmat?',
                message: '',
                okButton: 'Törlés',
                noButton: 'Mégsem',
                callback: function (value) {
                    if (value) {
                        window.location.href = url;
                    }
                }
            });
        });

        /**
         * Clone item
         */
        $(document).on('click', '.clone-item', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var current_button = $(this);

            // Open customized confirmation dialog window
            $.fancyConfirm({
                title: 'Biztosan duplikálod a tartalmat?',
                message: '',
                okButton: 'Biztosan',
                noButton: 'Mégsem',
                callback: function (value) {
                    if (value) {
                        window.location.href = url;
                    }
                }
            });
        });

        /**
         * Nestable list
         */
        var updateOutput = function (e) {
            var list = e.length ? e : $(e.target),
                output = list.data('output');
            if (window.JSON) {
                output.val(window.JSON.stringify(list.nestable('serialize')));
            } else {
                output.val('A böngésződ nem támogatja ezt a funkciót, használj Chromeot vagy Firefoxot!');
            }
        };

        if ($('#nestable-wrapper').length) {
            var maxDepth = $('#nestable-wrapper').data('max-depth') ? $('#nestable-wrapper').data('max-depth') : 1;

            $('#nestable-wrapper').nestable({
                group: 1,
                maxDepth: maxDepth,
            }).on('change', updateOutput);

            // Output initial serialised data
            updateOutput($('#nestable-wrapper').data('output', $('#nestable-output')));
        }

        $('#nestable-menu').on('click', function (e) {
            var target = $(e.target),
                action = target.data('action');
            if (action === 'expand-all') {
                $('.dd').nestable('expandAll');
            }
            if (action === 'collapse-all') {
                $('.dd').nestable('collapseAll');
            }
        });

        $(document).on('change', '.custom-file-input', function (e) {
            // Get the file name
            var fileName = e.target.files[0].name;
            // Replace the "Choose a file" label
            $(this).next('.custom-file-label').html(fileName);
        })

        // Scroll to first invalid field, and add focus to the end of the text
        if ($('.is-invalid').length) {
            let old_text = $('.is-invalid:visible:first').val();
            $('.is-invalid:visible:first').val('').val(old_text).focus();
            $('html, body').animate({
                scrollTop: $('.is-invalid:visible:first').offset().top
            }, 1000);
        }

        // Scroll to invalid recaptcha
        if ($('.is-invalid').length == 0 && $('.invalid-feedback').length) {
            $('html, body').animate({
                scrollTop: $('.invalid-feedback:visible:first').offset().top - 40
            }, 1000);
        }

        $(document).on('click', 'label[data-modal-open]', function (e) {
            e.preventDefault();
            let modal_id = $(this).data('modal-open');
            $('#' + modal_id).modal('show');
        });

        $(document).on('click', '#check_all', function (e) {
            $('input:checkbox').not(this).prop('checked', this.checked);
        });
    });
})(jQuery);
