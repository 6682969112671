(function ($) {
    "use strict";
    $(function () {

        // Change url on click gallery nav-link
        $(document).on('click', '.gallery .nav-link', function (e) {
            e.preventDefault();
            var event_id = $(this).data('id');
            let galleryUrl = $(this).parent().data('gallery-url');

            if (galleryUrl) {
                window.history.pushState('', '', $(this).parent().data('gallery-url') + '/' + event_id);
            }
        });
    });
})(jQuery);
