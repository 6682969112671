(function ($) {
    "use strict";
    $(function () {

        function check_billing_or_post_checkbox_prop() {
            if ($("#hide_billing_data").prop("checked")) {
                $(".billing_inputs").hide();
            } else {
                $(".billing_inputs").show();
            }

            if ($("#hide_post_data").prop("checked")) {
                $(".post_inputs").hide();
            } else {
                $(".post_inputs").show();
            }
        }

        check_billing_or_post_checkbox_prop();

        $("#hide_billing_data, #hide_post_data").click(function () {
            check_billing_or_post_checkbox_prop();
        });

        $('#users-filter').submit(function (e) {
            e.preventDefault();

            const roles_select = $('#roles_select').val();
            const interests_select = $('#interests_select').val();
            const participated_interests_select = $('#participated_interests_select').val();
            const guessed_interests_select = $('#guessed_interests_select').val();
            const newsletter_select = $('#newsletter_select').val();
            const company_assignment_select = $('#company_assignment_select').val();
            const registrations_counter = $('input[name=registrations_counter]').val();
            const scope_of_activities_select = $('#scope_of_activities_select').val();
            const banned_mail_select = $('#banned_mail_select').val();
            const q = $('input[name=q]').val();

            let order_by = $('#order_by').val();
            let order_dir = $('#order_dir').val();
            let page = $('#page').val();
            let params = '';

            if (roles_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'roles_select=' + roles_select;
            }

            if (interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'interests_select=' + interests_select;
            }

            if (participated_interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'participated_interests_select=' + participated_interests_select;
            }

            if (guessed_interests_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'guessed_interests_select=' + guessed_interests_select;
            }

            if (newsletter_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'newsletter_select=' + newsletter_select;
            }

            if (company_assignment_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'company_assignment_select=' + company_assignment_select;
            }

            if (registrations_counter) {
                params === '' ? params = '?' : params += '&';
                params += 'registrations_counter=' + registrations_counter;
            }

            if (scope_of_activities_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'scope_of_activities_select=' + scope_of_activities_select;
            }

            if (banned_mail_select.length) {
                params === '' ? params = '?' : params += '&';
                params += 'banned_mail_select=' + banned_mail_select;
            }

            if (q) {
                params === '' ? params = '?' : params += '&';
                params += 'q=' + q;
            }

            if (order_dir) {
                params === '' ? params = '?' : params += '&';
                params += 'order_dir=' + order_dir;
            }

            if (order_by) {
                params === '' ? params = '?' : params += '&';
                params += 'order_by=' + order_by;
            }

            if (page) {
                params === '' ? params = '?' : params += '&';
                params += 'page=' + page;
            }

            const url = $('#users-filter').attr('action') + params;
            const ajaxUrl = $('#users-filter').attr('action') + 'Filter' + params;

            history.pushState({url: url, title: url}, url, url);

            $.ajax({
                url: ajaxUrl,
                method: 'GET',
                dataType: 'json',
                success(res) {
                    $('.ajax-content').empty();

                    if (res.usersContentHTML != null) {
                        $('.ajax-content').html(res.usersContentHTML);
                        let exportUrl = new URL($('#export-users-form').attr('action'));
                        exportUrl.search = window.location.search;
                        $('#export-users-form').attr('action', exportUrl.href);

                        // No content and current page is not 1 -> return to first page
                        if (res.current_page_users == 0 && $('#page').val() != 1) {
                            $('#page').val(1);
                            $('#users-filter').submit();
                        }
                    }
                },
                error(res) {
                    console.log(res);
                }
            });
        });

        function getURLParameter(url, name) {
            return (RegExp(name + '=' + '(.+?)(&|$)').exec(url) || [, null])[1];
        }

        $(document).on('click', '.js-order-by', function (e) {
            e.preventDefault();
            let change_dir = ($('#order_by').val() == $(this).data('order-by')) ? true : false;

            $('#order_by').val($(this).data('order-by'));

            if (change_dir) {
                $('#order_dir').val() === 'asc' ? $('#order_dir').val('desc') : $('#order_dir').val('asc');
            } else {
                $('#order_dir').val('asc');
            }

            if ($('#users-filter').length) {
                $('#users-filter').submit();
            }

            if ($('#mailgroup-users-filter').length) {
                $('#mailgroup-users-filter').submit();
            }
        });

        $(document).on('click', '.ajax-content a.page-link', function (e) {
            e.preventDefault();
            $('#page').val(getURLParameter($(this).attr('href'), 'page'));

            if ($('#users-filter').length) {
                $('#users-filter').submit();
            }
            if ($('#mailgroup-users-filter').length) {
                $('#mailgroup-users-filter').submit();
            }
        });

        $(document).on('click', '.js-btn-reset ', function (e) {
            if ($('#users-filter').length) {
                $('#page, #order_by, #order_dir, #registrations_counter, #search').val('');
                $('.js-custom-select.selectized:not(.no-js)').each(function (i, obj) {
                    obj.selectize.clear(true);
                });
                $('#users-filter').submit();
            }

            if ($('#mailgroup-users-filter').length) {
                $('#page, #order_by, #order_dir, #registrations_counter, #search_company_assignment, #search, #id_not_in').val('');
                $('.js-custom-select.selectized:not(.no-js)').each(function (i, obj) {
                    obj.selectize.clear(true);
                });
                $('#mailgroup-users-filter').submit();
            }
        });

        $('#users-filter').on('change', function () {
            $('#page').val('');
            $("#users-filter").submit();
        });

        if ($('#users-filter').length) {
            $('#users-filter').submit();
        }
    });
})(jQuery);
